import TextFields from 'text-fields';
import SelectOptions from 'select-options';
import Tickbox from 'tickbox';
import './formHandler';

const signupForm = {
  selectOptions: new SelectOptions({ mobileMode: true }),
  tickbox: new Tickbox(),
  textFields: new TextFields(),

  async initFormElems() {
    const elems = [this.textFields, this.selectOptions, this.tickbox];
    await Promise.all(elems.map((elem) => elem.init()));
  },

  switchCurrencyLocale() {
    const switcher = () => {
      const bodyElement = document.body;
      const selectElement = document.querySelector('select[name="currency"]');

      selectElement.value = bodyElement.classList.contains('locale-kz') ? 'KZT' : 'RUB';
    };

    switcher();

    const observer = new MutationObserver(switcher);

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
  },

  async init() {
    this.switchCurrencyLocale();
    await this.initFormElems();
  },
};

export default signupForm;
